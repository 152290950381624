import Pusher from "pusher-js";

/*  This Pusher Connector need two input, channel name to build connection,
    and vuex store so after receive update it can directly access your store
    to perform dispatch, commit, or whatever it needed to do
 */
class PusherConnector {
  constructor(channelName, store) {
    this.store = store;
    const token = localStorage.getItem("accessToken");
    /* put subscription type here and don't forget make the function below */
    this.events = {
      dailyInventory: this.onDailyInventory,
      orderHistory: this.onOrderHistory,
    };
    this.channelName = channelName;
    this.pusher = this.initPusher(`Bearer ${token}`);
    const channel = this.pusher.subscribe(channelName);

    channel.bind("lighthouse-subscription", (msg) => {
      if (
        this.events[Object.keys(msg.result.data)]
        && typeof this.events[Object.keys(msg.result.data)] === "function"
      ) {
        this.events[Object.keys(msg.result.data)](msg.result.data[Object.keys(msg.result.data)]);
      }
    });
  }

  initPusher = (authorization) => new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authEndpoint: `${process.env.VUE_APP_GRAPHQL_BASE_URL}/subscriptions/auth`,
    auth: {
      headers: {
        authorization,
      },
    },
    activityTimeout: 3600000,
  });

  disconnect = () => {
    this.pusher.unsubscribe(this.channelName);
    this.pusher.disconnect();
  };

  onDailyInventory = (data) => {
    this.store.dispatch("StockProductStore/updatedDailyInventoryStock", data);
  };

  onOrderHistory = (data) => {
    this.store.dispatch("OrderDeliveryStore/updatedOrderHistory", data);
  };
}

export default PusherConnector;
